<template>
    <div align="center">
        <br><br>

        <div class="row justify-center">
            <div class="col-12 col-md-8">
                <QQButton label="Elenco Pratiche" color="blue-grey" icon="mdi-clipboard-check-multiple-outline" size="xl" @click.native="onElencoPratiche()"/>
                <QQButton label="Gestione Rinnovi" color="blue-grey" icon="mdi-arrange-send-backward" size="xl" @click.native="onRinnoviPraticheSede()" v-if="isSede"/>
                <QQButton label="Rinnovi" color="blue-grey" icon="mdi-arrange-send-backward" size="xl" @click.native="onRinnoviPraticheUfficio()"/>
            </div>

            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    import {mapFields} from "vuex-map-fields"
    import { mapState } from "vuex";


    export default {
        name: "MenuGenerale",
        data() {
            return {
                annotazione: ""
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestionePratiche", {

            }),
            ...mapState({
                formPratica: state => state.gestionePratiche.formPratica,
                is_loading: state => state.gestionePratiche.is_loading
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            onElencoPratiche() {
                this.$router.push({name : "Pratiche.ElencoPratiche"});
            },
            onRinnoviPraticheSede() {
                this.$router.push({name : "Rinnovi.RinnovaDaSede"});
            },
            onRinnoviPraticheUfficio() {
                this.$router.push({name : "Rinnovi.RinnovaDaUfficio"});
            }
        },
        async mounted() {
            this.$store.commit("setArea","professionisti");
        }
    }

</script>
